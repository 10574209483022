import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import { accountCreditCardsSelector, accountProfileSelector } from 'state/account/account.selectors';
import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import PaymentMethods from 'components/payment-methods/payment-methods.components';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import { medicineCabinetAutoRefillPlanEligibleSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

const PaymentSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const displayAutoRefillMessage = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const profileObject = useSelector(accountProfileSelector);

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, [dispatch, profileObject]);

    const showLoading = allPaymentData === undefined;

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.payment.eyebrowText`)}
            title={t(`pages.profile.payment.title`)}
            heading={t(`pages.profile.payment.heading`)}
        >
            <Container fluid>
                {displayAutoRefillMessage && <div className="px-4">{t('pages.profile.payment.autoRefillSubhead')}</div>}
                <LoadingMessage isVisible={showLoading} text={t(`pages.profile.payment.loading`)} />
                {allPaymentData && (
                    <PaymentMethods
                        addNewPaymentButtonLabel={t('pages.profile.payment.labels.addPaymentMethod')}
                        paymentData={allPaymentData}
                        removeCardLinkClassName="text-primary"
                        showLabel
                        showRemoveCardLink
                        showSetDefaultLink
                    />
                )}
            </Container>
        </ProfileLayout>
    );
};

export default PaymentSection;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
